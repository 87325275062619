.App {
  text-align: center;
}

.header {
  font-family: "VT323";
  color: white;
  margin: 1rem;
  font-size: 3.5rem;
  background: rgb(20, 20, 212);
  margin: 0;
  border-radius: 0 0 1rem 1rem;
  margin-bottom: 2rem;
  padding: 1rem;
}

.flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.card {
  width: 20rem;
  margin: 1rem;
  height: 12rem;
}

.poster {
  width: 20rem;
  height: 12rem;
  border-radius: 1rem;
}

.opacity--none {
  opacity: 0;
}

.MuiSlider-root {
  color: rgb(20, 20, 212) !important;
}